/**
 * Foundation Init
 *
 * Note: This script is hardcoded in footer.php, after wp_footer, because it
 * must occur after all Foundation plugins have been loaded -- but as they are
 * enqueued conditionally, we don't know when that will be.
 */

if ( typeof jQuery(document).foundation !== 'undefined' )
{
	jQuery(document).foundation();
}
else
{
	console.error( '[FoundationCustom] Could not init Foundation: "jQuery(document).foundation" is not a function. Please ensure foundation.core is enqueued' );
}
